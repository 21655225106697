import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import BHLink from '../link'
import IconHeadArrowDown from '../../atoms/icons/arrowhead-down-icon'

const shouldForwardProp = (prop) => !['columns', 'toggleDropdown', 'routeChanging', 'iconDropdownProps'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(${({ columns }) => columns}, max-content);
	grid-gap: 7.5%;
	pointer-events: ${({ routeChanging }) => (routeChanging ? 'none' : 'auto')};
`

const NavItem = styled(Typography)`
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	color: ${({ theme }) => theme.palette.primary.main};
	padding: var(--spacing-2) 0;

	> .MuiSvgIcon-root {
		transition: transform 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
		color: ${({ theme }) => theme.palette.grey.main};
	}

	&.nav-dropdown-opened {
		font-weight: ${({ theme }) => theme.typography.fontWeightBold};
		.MuiSvgIcon-root {
			transform: rotate3d(0, 0, 1, -180deg);
		}
		a:after {
			background-color: ${({ theme }) => theme.palette.grey.main};
		}
	}
	&.nav-active {
		a:after {
			width: 100%;
		}
	}
`

const LinkRoot = styled('span')`
	display: flex;
	align-items: center;
`
const Link = styled(BHLink, { shouldForwardProp })`
	color: inherit;
	cursor: pointer;
	padding: 0 5px;
	display: inline-block;
	line-height: 18px;
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	text-transform: uppercase;
	font-family: var(--font-alternative-regular);
	// &:after {
	// 	content: '';
	// 	display: block;
	// 	width: 0px;
	// 	height: 1px;
	// 	background-color: ${({ theme }) => theme.palette.primary.main};
	// 	margin-top: -1px;
	// 	transition: width 0.3s;
	// 	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	// }
`

const IconDropdown = styled(IconHeadArrowDown)`
	cursor: pointer;
	display: none;
`

export { NavMenu, NavItem, LinkRoot, Link, IconDropdown }
