import isPropValid from '@emotion/is-prop-valid'
import Link from '../link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	isPropValid(prop) &&
	!['type', 'component', 'items', 'maxItemsForColumn', 'parentMaxItemsForColumn', 'key'].includes(prop)

const DropDownWrapper = styled('div')`
	padding: var(--spacing-1);
	display: block;
`

const NavLinkStyled = styled(Link)`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	margin-bottom: var(--spacing-2);
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: var(--color-primary);
		margin-top: 1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
	&:hover:after {
		width: 100%;
	}
	&.no-link {
		cursor: default;
	}
	&.item-view-all {
		font-weight: 700;
		margin-bottom: 0;

		&:after {
			display: none;
		}
	}
`

const DropDrownList = styled(List, {
	shouldForwardProp,
})`
	position: revert;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(5, minmax(max-content, 1fr));
	grid-template-rows: 1fr;
	grid-gap: var(--spacing-1);
	align-items: flex-start;
	padding: 0;
	width: 100%;
	background: var(--color-secondary);
	[class*='highlight'] {
		background: var(--color-grey50);
	}

	.DropDrownList-item {
		[class*='banner-hover-trigger'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			z-index: 1;
			align-items: flex-start;
			&._show {
				visibility: visible;
				opacity: 1;
			}
		}
		[class*='default-hover-trigger'] {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			z-index: 1;
			&._hide {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
`

const DropDrownListItem = styled(ListItem, {
	shouldForwardProp,
})`
	display: block;
	padding: 24px;
	height: 100%;
	transition: background var(--transition);

	${({ theme }) => theme.breakpoints.up('xl')} {
		padding: var(--spacing-4) 36px;
	}

	&.level-1 > .MuiLink-root:not(.item-view-all) {
		${({ theme }) => theme.typography.h4}
		margin-bottom: var(--spacing-3);
		&:after {
			display: none;
		}
	}

	&.banner {
		padding: 0;
		display: flex;
		align-items: flex-start;
		grid-column-start: ${({ columnId }) => `${columnId}`};
		width: auto;
		> div {
			flex: 1;
		}
	}
	.MuiListItem-root {
		padding: 0;
		min-height: 0;
		height: auto;
		background: unset;
	}
	& .MuiList-root {
		display: block;
		padding: 0;
		background: unset;

		& .MuiListItem-root {
			padding-left: 0;
		}
	}
	&.menu-item-current {
		> a:not(.item-view-all):after {
			width: 100%;
		}
		&:hover {
			> a:not(.item-view-all):after {
				background-color: var(--color-grey30);
			}
		}
	}
`

const AdditionalBlock = styled('div')`
	width: 100%;
	padding: 12px 68px;
	text-align: center;
	border-top: 1px solid var(--color-grey40);
	& p {
		margin: 0;
	}
`
const ChildrenWrapper = styled('div')`
	display: flex;
	gap: 15px;
`

export { DropDownWrapper, DropDrownList, DropDrownListItem, NavLinkStyled, AdditionalBlock, ChildrenWrapper }
