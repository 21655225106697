import { cx } from '@emotion/css'
import { array, bool, func, string } from 'prop-types'
import { useCallback, useState, memo } from 'react'
import { NavItem as NavItemStyled, NavMenu } from './menu-first-level.styled'
import NavLink from './menu-nav-link'
import { useRouter } from 'next/router'
import { menuHasActiveItems, menuItemIsActive } from '@bluheadless/ui-logic/src/utils/menu'
import isEqual from 'lodash/isEqual'

const NavItem = memo(
	function NavItem({ item, className, children }) {
		const { level, cssClasses } = item
		const { asPath } = useRouter()
		const active = menuItemIsActive(item, asPath)
		const hasActiveChildren = menuHasActiveItems(item.children, asPath)

		return (
			<NavItemStyled
				className={cx(className, 'menu-item', 'level-' + level, cssClasses, {
					'nav-active': active || hasActiveChildren,
				})}
				variant="body2"
				component="div"
			>
				{children}
			</NavItemStyled>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.className === nextProps.className && isEqual(prevProps.item, nextProps.item)
	}
)

const MenuFirstLevel = ({
	eventType,
	items,
	openDropdown,
	setDropdownItems,
	setParentCssClasses,
	setParentMaxItemsForColumn,
	toggleDropdown,
	iconDropdownProps,
	className,
	routeChanging,
	...props
}) => {
	const [current, setCurrent] = useState(null)

	const navLinkHandler = useCallback(
		(itemId) => {
			const itemChildren = items.find((item) => item.id === itemId)?.children
			const itemClasses = items.find((item) => item.id === itemId)?.cssClasses
			const maxItemsForColumn = items.find((item) => item.id === itemId)?.maxItemsForColumn

			if (itemChildren?.length > 0) {
				if (!openDropdown) {
					toggleDropdown(true)
					setDropdownItems(itemChildren)
					setParentCssClasses(itemClasses)
					setParentMaxItemsForColumn(maxItemsForColumn)
					setCurrent(itemId)
				} else {
					// if click on the same menu item
					if (current === itemId && eventType === 'click') {
						setCurrent(null)
						setDropdownItems(null)
						setParentCssClasses(null)
						setParentMaxItemsForColumn(null)
						toggleDropdown(false)
					} else {
						// if hover on the same item don't close submenu
						setCurrent(itemId)
						toggleDropdown(true)
						setParentCssClasses(itemClasses)
						setDropdownItems(itemChildren)
						setParentMaxItemsForColumn(maxItemsForColumn)
					}
				}
			} else {
				setCurrent(null)
				setDropdownItems(null)
				setParentCssClasses(null)
				setParentMaxItemsForColumn(null)
				toggleDropdown(false)
			}
		},
		[
			items,
			openDropdown,
			toggleDropdown,
			setDropdownItems,
			setParentCssClasses,
			setParentMaxItemsForColumn,
			current,
			eventType,
		]
	)

	return (
		<NavMenu
			className={cx('NavMenu-root', className)}
			aria-label="menu"
			columns={items.length}
			routeChanging={routeChanging}
			{...props}
		>
			{items.map((item) => {
				const { children, id, url, urlKey, path, target, title, type, entityId, identifier } = item
				return (
					<NavItem
						key={id}
						item={item}
						className={cx({ 'nav-dropdown-opened': current && current === id && openDropdown })}
					>
						<NavLink
							type={type}
							url={path ?? urlKey ?? url ?? null}
							itemId={id}
							target={target}
							title={title}
							hasChildren={children?.length > 0}
							eventType={eventType}
							entityId={entityId}
							identifier={identifier}
							navLinkHandler={navLinkHandler}
							iconDropdownProps={iconDropdownProps}
							toggleDropdown={toggleDropdown}
						/>
					</NavItem>
				)
			})}
		</NavMenu>
	)
}

MenuFirstLevel.defaultProps = {}

MenuFirstLevel.propTypes = {
	eventType: string,
	items: array.isRequired,
	openDropdown: bool,
	setDropdownItems: func,
	toggleDropdown: func,
}

export default MenuFirstLevel
